import { useEffect, useRef } from "react";
import SectionTitle from "../../../components/section-title";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonial from "../../../components/testimonial";
import HomeData from "../../../data/home.json";
import SwiperCore, { Navigation } from "swiper";
import Parallax from "parallax-js";

SwiperCore.use([Navigation]);
const TestimonialContainer = () => {
    const swiperOption = {
        loop: false,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: ".testimonial-carousel .swiper-button-next",
            prevEl: ".testimonial-carousel .swiper-button-prev",
        },
    };
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="testimonial-section section-py position-relative">
            <img
                className="tetimonial-shape"
                src={`${process.env.PUBLIC_URL}/images/testimonial/shape.png`}
                alt=""
            />
            <div className="container">
                <div className="row ">
                    <div className="col-xl-7 col-lg-6 mb-7">
                        <div
                            className="testimonial-photo scene mt-10 mt-lg-0"
                            id="scene"
                            ref={sceneEl}
                        >
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mb-7">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section text-center"
                                subTitle="NOS SERVICES DE FABRICATION ÉLECTRONIQUE"
                                title="Les services<span class='text-primary'> que nous proposons </span> sont les suivants:"
                            />
                            <h5 className="happy-customer fs-5 text-center">
                                Nous réalisons le câblage de vos plaques électroniques 
                                avec nos robots de pose de CMS et complétons 
                                la prestation avec la pose de composants 
                                traditionnels pour finaliser vos cartes électroniques. 
                                Nous procédons à la soudure par refusions puis effectuons 
                                des contrôles visuels et automatiques (AOI)
                                tout au long du process.<br />
                                Nous suivons les directives de la norme IPC-A-610 pour la fabrication et le contrôle de nos cartes électroniques.
                                Quant aux contrôles qualité ponctuant chaque étape de production ils vous garantissent un résultat irréprochable
                            </h5>
                        </div>
                        <div className="testimonial-carousel position-relative">
                            <div className="row justify-content-between">
                                <div className="col-2 text-center">
                                    <div activeClassName="active">
                                        <img alt="active" src={`${process.env.PUBLIC_URL}/images/icon/Control Panel-rafiki.svg`} />
                                        Sourcing des composants
                                    </div>
                                </div>
                                <div className="col-2 text-center">
                                    <div activeClassName="active">
                                        <img alt="active" src={`${process.env.PUBLIC_URL}/images/icon/Printed circuit board-bro.svg`} />
                                        Fabrication et montage de vos PCB
                                    </div>
                                </div>
                                <div className="col-2 text-center">
                                    <div activeClassName="active">
                                        <img alt="active" src={`${process.env.PUBLIC_URL}/images/icon/Heavy box-amico.svg`} />
                                        intégration en coffret
                                    </div>
                                </div>
                                <div className="col-2 text-center">
                                    <div activeClassName="active">
                                        <img alt="active" src={`${process.env.PUBLIC_URL}/images/icon/Hand coding-bro.svg`} />
                                        Programmation et sérialisation
                                    </div>
                                </div>
                                <div className="col-2 text-center">
                                    <div activeClassName="active">
                                        <img alt="active" src={`${process.env.PUBLIC_URL}/images/icon/QA engineers-bro.svg`} />
                                        Paramétrage et test des cartes
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialContainer;
