import React from "react";
import BlogData from "../../../data/blog.json";
import SectionTitleTwo from "../../../components/section-title-two";
import BlogGrid from "../../../components/blog";

const HomeBlog = () => {
    return (
        <div className="blog-section section-pb">
            <div className="container">
                <SectionTitleTwo
                    classOption="title-section mb-lg-12 pb-lg-1"
                    subTitle="Démarche Générale"
                    title="Démarche <span class='text-primary'> et Méthodologie</span> de conception"
                    excerptClassOption="mt-lg-12 pt-lg-12 mb-12 mb-lg-12"
                />
                <div className="row">
                    {BlogData &&
                        BlogData.slice(1,4).map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="null"
                                        key={key}
                                        data={single}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default HomeBlog;
