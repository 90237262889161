import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";

const HomeAboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Qui sommes nous ?"
                                title="NEXTRONIC EST UN ACTEUR CLÉ 
                                <span class='text-primary'>
                                    DES TECHNOLOGIES 
                                </span>
                                <br className='d-none d-xl-block' />
                                COMMUNICANTES"
                            />
                            <p className="high-light">
                                Nous mettons nos expertises et savoir-faire au
                                service de nos clients (État, grands comptes,
                                PME, start-up et universités…) pour les aider à
                                relever les défis liés à la conception des
                                toutes dernières technologies électroniques et
                                logicielles, et aussi pour proposer des
                                solutions complètes qui répondent aux attentes
                                de ces derniers.
                            </p>
                            <p className="high-light">
                                Depuis 2014, NEXTRONIC déploie son expertise
                                pour créer des solutions IoT innovantes et
                                sur-mesure, adaptables en fonction des cas
                                d’usage et de l'environnement. NEXTRONIC
                                regroupe une communauté de spécialistes en
                                conception de systèmes électroniques passionnés
                                par leur métiers et par les nouvelles
                                technologies.
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Learn more"
                                path="/about"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/MOTHERBOARD.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
            />
        </div>
    );
};

export default HomeAboutContainer;
